export const useVercelEnv = () => {
  const config = useRuntimeConfig();

  return {
    API_BASE_URL: config.public.API_BASE_URL,
    API_WHATSAPP_SEND_URL: config.public.API_WHATSAPP_SEND_URL,
    APP_ENV: config.public.APP_ENV,
    APP_REDIRECT_FAILBACK_TIMER_IN_SECONDS: parseInt(String(config.public.APP_REDIRECT_FAILBACK_TIMER_IN_SECONDS)),
    APP_WHATSAPP_SEND_URL: config.public.APP_WHATSAPP_SEND_URL,
    DEFAULT_REDIRECT_TIMER_IN_SECONDS: config.public.DEFAULT_REDIRECT_TIMER_IN_SECONDS,
    INGESTOR_SERVICE_TIMEOUT: parseInt(String(config.public.INGESTOR_SERVICE_TIMEOUT)),
    INGESTOR_SERVICE_URL: config.public.INGESTOR_SERVICE_URL,
    REDIRECT_FAILBACK_TIMER_IN_SECONDS: parseInt(String(config.public.REDIRECT_FAILBACK_TIMER_IN_SECONDS)),
    REDIRECTOR_ANDROID_FORCE_FAILBACK: parseInt(String(config.public.REDIRECTOR_ANDROID_FORCE_FAILBACK)),
    REDIRECTOR_IOS_FORCE_FAILBACK: parseInt(String(config.public.REDIRECTOR_IOS_FORCE_FAILBACK)),
    REDIRECTOR_UNSUPPORTED_INSTAGRAM_VERSIONS: config.public.REDIRECTOR_UNSUPPORTED_INSTAGRAM_VERSIONS,
    SENTRY_DSN_PUBLIC: config.public.SENTRY_DSN_PUBLIC,
    SENTRY_ERROR_REPLAY_SAMPLE_RATE: parseFloat(String(config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE) ?? '0'),
    SENTRY_REPLAY_SAMPLE_RATE: parseFloat(String(config.public.SENTRY_REPLAY_SAMPLE_RATE)),
    SENTRY_TRACES_SAMPLE_RATE: parseFloat(String(config.public.SENTRY_TRACES_SAMPLE_RATE)),
    TRACK_PIXEL_URL: config.public.TRACK_PIXEL_URL,
    WEB_WHATSAPP_SEND_URL: config.public.WEB_WHATSAPP_SEND_URL
  };
};
